<template>
  <land-section
    id="my_upload"
    space="40"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 1000px;"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-card
            class="putin-article rounded pa-6"
            color="white"
            flat
            tile
            height="1400"
          >
            <el-form
              v-if="wdForm.state === 'issue'"
              ref="frmArticle"
              :model="wdForm"
              :rules="wdRule"
              label-width="100px"
              label-position="top"
            >
              <el-form-item
                :label="wdTitle.label"
                prop="title"
              >
                <el-input
                  v-model="wdForm.title"
                  :maxlength="wdTitle.max"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                :label="txtTags.label"
                :prop="txtTags.name"
              >
                <el-select
                  v-model="txtTags.value"
                  placeholder="选择标签"
                  style="width: 320px;"
                >
                  <el-option
                    v-for="tag in txtTags.items"
                    :key="tag"
                    :label="tag"
                    :value="tag"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="wdCover.label"
                prop="cover"
              >
                <div class="co-flex-col">
                  <el-upload
                    :ref="wdCover.name"
                    :action="wdCover.action"
                    :accept="wdCover.accept"
                    :headers="uploadHeader"
                    :limit="wdCover.limit"
                    :file-list="wdCover.files"
                    :auto-upload="wdCover.autoUpload"
                    :show-file-list="false"
                    :before-upload="onCoverBeforeUpload"
                    :on-success="onCoverSuccess"
                    class="cover-art-uploader"
                  >
                    <img
                      v-if="wdCover.value"
                      :src="wdCover.value"
                      class="cover-article"
                    >
                    <i
                      v-else
                      class="el-icon-plus cover-art-uploader-icon"
                    />

                    <div
                      slot="tip"
                      class="co-text-xs blue-grey--text"
                      style="line-height: 100%;"
                    >
                      {{ wdCover.tip }}
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
            </el-form>
            <div
              v-if="wdForm.state === 'issue'"
              class="quill-editor mt-12"
            >
              <el-upload
                id="uploadImg"
                ref="uploadImg"
                multiple
                style="display: none;"
                :action="uploadUrl"
                :headers="uploadHeader"
                accept=".jpg,.jpeg,.png,.gif"
                :show-file-list="false"
                :on-success="onImageSuccess"
                :before-upload="onImageBeforeUpload"
              >
                上传
              </el-upload>
              <quill-editor
                ref="myQuillEditor"
                v-model="content"
                :options="editorOptions"
                @focus="onEditorFocus($event)"
                @blur="onEditorBlur($event)"
                @change="onEditorChange($event)"
              />
            </div>

            <div
              v-if="wdForm.state === 'issue'"
              class="co-flex-col"
            >
              <span class="blue-grey--text my-2">
                {{ tipIdentity }}
              </span>
              <div
                class="co-flex-row co-justify-between co-items-center"
              >
                <div class="co-flex-row co-items-center">
                  <el-button
                    :loading="btnIssue.loading"
                    type="primary"
                    :disabled="!allowed"
                    @click="onSubmit"
                  >
                    {{ btnIssue.text }}
                  </el-button>
                  <el-button
                    type="info"
                    :disabled="!allowed"
                    @click="onPreview"
                  >
                    {{ btnPreview.text }}
                  </el-button>
                </div>

                <div
                  v-if="imageable || videoable"
                  class="co-flex-row co-items-center"
                >
                  <el-button
                    v-if="imageable"
                    plain
                    @click="toIssueImage"
                  >
                    {{ btnIssueImg.text }}
                  </el-button>
                  <el-button
                    v-if="videoable"
                    plain
                    @click="toIssueVideo"
                  >
                    {{ btnIssueVid.text }}
                  </el-button>
                </div>
              </div>
            </div>

            <div
              v-if="wdForm.state !== 'issue'"
              class="co-flex-col co-justify-center co-items-center"
            >
              <v-icon
                size="160"
                :color="resultTip.color || 'grey'"
              >
                {{ resultTip.icon }}
              </v-icon>
              <span
                class="co-text-md blue-grey--text lighten-2 mt-4"
              >
                {{ resultTip.hint }}
              </span>
              <v-btn
                dark
                width="180"
                height="40"
                :color="resultTip.color"
                class="mt-8"
                @click="onNext"
              >
                {{ resultTip.next }}
              </v-btn>
              <div class="co-w-full co-h120" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <article-preview ref="dlgPreview" />
    <dialog-alert ref="dlgAlert" />
  </land-section>
</template>

<script>

  import api from '@/api/co.api'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'

  import Quill from 'quill'
  import ImageResize from 'quill-image-resize-module'

  Quill.register('modules/imageResize', ImageResize)

  export default {
    metaInfo: { title: '文章' },
    components: {
      ArticlePreview: () => import('@/pages/sections/my/ArticlePreview.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      params: {
        type: Object,
        default: () => {
          return {}
        }
      },
      userInfo: {
        type: Object,
        default: () => {
          return {}
        }
      },
      imageable: {
        type: Boolean,
        default: false
      },
      videoable: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        uploadUrl: `${api.url.web_util_file_upload}?folder=image`,
        uploadHeader: {},
        content: '',
        info: {},
        wdTitle: {
          label: '标题',
          max: 64
        },
        wdCover: {
          label: '封面',
          name: 'upload_cover',
          action: `${api.url.web_util_file_upload}?folder=image`,
          accept: '.jpg,.jpeg,.png,.gif',
          autoUpload: true,
          tip: '只能上传jpg/png/gif文件，且图片不能超过5MB',
          headers: {},
          limit: 1,
          value: '',
          files: []
        },
        wdForm: {
          state: 'issue',
          title: '',
          cover: '',
        },
        txtTags: {
          label: '作品所属标签',
          name: 'activityTag',
          value: '',
          items: [],
        },
        wdRule: {
          title: [
            { required: true, message: '请输参数作品标题', trigger: 'blur' },
            { min: 2, max: 64, message: '长度在 2 到 64 个字符', trigger: 'blur' }
          ],
          cover: [
            { required: true, message: '请设置封面', trigger: 'blur' },
          ]
        },
        tagRules: [
          { required: true, message: '必须选择所属标签', trigger: 'blur' },
        ],
        btnIssue: {
          loading: false,
          text: '提交图文作品'
        },
        btnPreview: {
          text: '预览'
        },
        btnIssueImg: {
          disabled: false,
          text: '提交图片作品'
        },
        btnIssueVid: {
          disabled: false,
          text: '提交视频作品'
        },
        editor: null,
        addRange: [],
        editorOptions: {
          theme: 'snow',
          debug: 'warn',
          placeholder: '书写你的内容',
          readOnly: false,
          modules: {
            imageResize: {
              displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
              },
              modules: ['Resize', 'DisplaySize']
            },
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '30', '32', '36', '40', '44', '48'] }],
              [{ header: [1, 2, 3, 4, 5, 6] }],
              [{ color: [] }, { background: [] }],
              // [{ 'font': [] }],
              [{ align: [] }],
              ['clean'],
              ['link', 'image']
            ]
          },
        },
        allowIdentitys: [
          '医学生',
          '医务工作者'
        ],
        tipIdentity: '仅限于医学生及医务工作者投稿',
        allowed: true,
        tips: {
          success: {
            value: 'success',
            icon: 'mdi-check-circle-outline',
            color: 'success',
            hint: '图文作品提交成功',
            next: '返回活动页面',
            handler: this.toBack
          },
          error: {
            value: 'error',
            icon: 'mdi-close-circle-outline',
            color: 'error',
            hint: '图文作品提交失败',
            next: '重新提交',
            handler: this.toReissue
          }
        },
        resultTip: {},
      }
    },
    watch: {
      params: {
        handler (val) {
          const temp = val.articleTags || ''
          this.txtTags.items = web.comm.parseTags(temp, false)

          if (this.txtTags.items.length > 0) {
            this.txtTags.value = this.txtTags.items[0]
            this.wdRule[this.txtTags.name] = this.tagRules
          } else {
            this.wdRule[this.txtTags.name] = []
          }
        },
        immediate: true
      },
      userInfo: {
        handler (val) {
          const identity = val.identity || ''
          if (this.allowIdentitys.indexOf(identity) !== -1) {
            this.allowed = true
          } else {
            this.allowed = false
          }
        },
        immediate: true
      }
    },
    created () {
      this.configHeaders()
    },
    mounted () {
      this.editor = this.$refs.myQuillEditor.quill
      this.editor.getModule('toolbar').addHandler('image', this.imgHandler)
      this.configQuill()
    },
    beforeDestroy () {
      this.editor = null
      delete this.editor
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      setResultTip (val, message = '') {
        const tip = this.tips[val] || {}
        this.resultTip = Object.assign({}, tip)
        if (message) {
          this.resultTip.hint = `${tip.hint}(${message})`
        }
        this.wdForm.state = tip.value || 'issue'
      },
      toBack () {
        api.page.navigateBy(this, 'activityDetail', this.params)
      },
      toReissue () {
        this.issueContent()
      },
      onNext () {
        const handler = this.resultTip.handler
        if (typeof handler === 'function') {
          handler()
        }
      },
      toPreview (item) {
        const dlg = this.$refs['dlgPreview']
        if (dlg) {
          dlg.show(item)
        }
      },
      onContentPreview (index, item) {
        this.toPreview(item)
      },
      toIssueImage () {
        this.$emit('change-mode', 'image')
      },
      toIssueVideo () {
        this.$emit('change-mode', 'video')
      },
      configHeaders () {
        const obj = user.token.get()
        const headers = {
          token: obj.accessToken,
          uuid: obj.userId
        }
        this.uploadHeader = headers
      },
      configQuill () {
        const _toolbar = document.querySelector('.ql-toolbar')
        const _buttons = _toolbar.querySelectorAll('button')
        // const _selects = _toolbar.querySelectorAll('select')

        for (const i in _buttons) {
          const _btn = _buttons[i]
          // console.log('classList: %o', _btn.classList[0])
          let _className = ''
          if (_btn.classList) {
            _className = _btn.classList[0]
          } else {
            _className = _btn.className
          }
          switch (_className) {
            case 'ql-script':
              _btn.value === 'sub' ? _btn.title = '下标' : _btn.title = '上标'
              break
            case 'ql-indent':
              _btn.value === '+1' ? _btn.title = '向右缩进' : _btn.title = '向左缩进'
              break
            case 'ql-bold':
              _btn.title = '加粗'
              break
            case 'ql-color':
              _btn.title = '颜色'
              break
            case 'ql-font':
              _btn.title = '字体'
              break
            case 'ql-code':
              _btn.title = '插入代码'
              break
            case 'ql-italic':
              _btn.title = '斜体'
              break
            case 'ql-link':
              _btn.title = '添加链接'
              break
            case 'ql-background':
              _btn.title = '背景颜色'
              break
            case 'ql-size':
              _btn.title = '字体大小'
              break
            case 'ql-strike':
              _btn.title = '删除线'
              break
            case 'ql-underline':
              _btn.title = '下划线'
              break
            case 'ql-blockquote':
              _btn.title = '引用'
              break
            case 'ql-header':
              _btn.title = '标题'
              break
            case 'ql-list':
              _btn.title = '列表'
              break
            case 'ql-align':
              _btn.title = '文本对齐'
              break
            case 'ql-direction':
              _btn.title = '文本方向'
              break
            case 'ql-code-block':
              _btn.title = '代码块'
              break
            case 'ql-formula':
              _btn.title = '公式'
              break
            case 'ql-image':
              _btn.title = '图片'
              break
            case 'ql-clean':
              _btn.title = '清除字体样式'
              break
          }
        }
      },
      onEditorFocus (editor) {
        // console.log('editor blur!', editor)
      },
      onEditorBlur (editor) {
        // console.log('editor focus!', editor)
      },
      onEditorChange (editor) {
        // console.log('editor change!', editor)
      },
      imgHandler (ev) {
        this.addRange = this.editor.getSelection()
        if (ev) {
          document.querySelector('#uploadImg .el-upload').click()
        }
      },
      onImageSuccess (res, file, fileList) {
        // console.log({ res, file, fileList })
        if (file.status === 'success') {
          const data = res.data || {}
          this.addRange = this.editor.getSelection()
          let _len = 0
          if (this.addRange) {
            _len = this.addRange.index
          }
          this.editor.insertEmbed(_len, 'image', data.fileUrl)
          this.editor.setSelection(_len + 1)
        }
      },
      onImageBeforeUpload (file) {
      },
      onCoverSuccess (res, file) {
        // console.log({ res, file })
        const resp = file.response || {}
        const data = resp.data || {}
        this.wdCover.value = data.fileUrl || ''
        this.wdForm.cover = data.fileUrl || ''
      },
      onCoverBeforeUpload (file) {
        const isLt2M = file.size / 1024 / 1024 <= 5

        if (!isLt2M) {
          this.$notify({
            title: '错误提示',
            message: '上传封面图片大小不能超过 5MB!',
            type: 'error'
          })
        }
        return isLt2M
      },
      getData () {
        const result = {
          activityId: this.params.activityId,
          userId: this.userInfo.userId,
          title: this.wdForm.title,
          cover: this.wdCover.value,
          activityTag: this.txtTags.value,
          type: web.comm.ContentTypes.ARTICLE,
          state: web.comm.EnrollStates.NOPASS,
          accessScope: web.comm.Scopes.PUBLIC,
          content: this.content
        }
        return result
      },
      issueContent () {
        const me = this
        const data = this.getData()
        const executing = function () {
          me.btnIssue.loading = true
        }
        const executed = function (res) {
          me.btnIssue.loading = false
          if (res.status) {
            me.info = res.data
            me.setResultTip('success')
            me.showAlert('success', '作品提交成功')
          } else {
            me.setResultTip('error')
            const err = res.data || {}
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        if (this.info.enrollId) {
          web.activityEnroll.update({
            id: this.info.enrollId,
            data,
            executing,
            executed
          })
        } else {
          web.activityEnroll.create({
            data,
            executing,
            executed
          })
        }
      },
      checkWidgets () {
        let result = ''
        const arr = []

        if (!this.wdForm.title) {
          arr.push('必须填写标题')
        }

        if (!this.wdForm.cover) {
          arr.push('必须上传封面')
        }

        result = arr.join('，')
        return result
      },
      checkIdentity () {
        let result = false
        const identity = this.userInfo.identity || ''
        if (this.allowIdentitys.indexOf(identity) !== -1) {
          result = true
        } else {
          result = false
        }
        return result
      },
      onSubmit () {
        if (!this.checkIdentity()) {
          this.showAlert('warning', this.tipIdentity)
          return
        }
        const message = this.checkWidgets()
        if (message) {
          this.$notify({
            title: '错误提示',
            message,
            type: 'error'
          })
          return
        }
        this.issueContent()
      },
      onPreview () {
        const message = this.checkWidgets()
        if (message) {
          this.$notify({
            title: '错误提示',
            message,
            type: 'error'
          })
          return
        }

        const data = this.getData()
        this.toPreview(data)
      },
    }
  }
</script>

<style>
.putin-article .el-form--label-top .el-form-item__label {
  padding-bottom: 4px !important;
  line-height: 26px !important;
}

.quill-editor {
  height: 700px;
  margin-bottom: 20px;
}

.ql-container.ql-snow {
  height: 600px;
}

.ql-snow .ql-picker {
  line-height: 28px !important;
  height: 28px;
}

.ql-snow .ql-toolbar button {
  width: 28px;
  height: 28px;
}

.cover-art-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .cover-art-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .cover-art-uploader-icon {
    font-size: 32px;
    color: #8c939d;
    width: 320px;
    height: 220x;
    line-height: 220px;
    text-align: center;
  }
  .cover-article {
    width: 320px;
    height: 220x;
    display: block;
  }

</style>
